import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import About from './About';
import Contact from './Contact'; // This might still be used if Contact is a standalone page
import Login from './login';
import Ad from './ad';
import Navbar from './Navbar';
import Footer from './Footer';

function App() {
  return (
    <Router>
<div 
  style={{
    background: 'linear-gradient(to bottom, #555, #333)', // Gradient from lighter grey (#555) to dark grey (#333)
    color: '#fff',
    minHeight: '100vh'
  }}
>
<Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <About />
                <Contact />
                <Footer />
              </>
            }
          />
          {/* Add other routes as necessary */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
